.section
	padding: r(40px) 0 r(80px)

.section-large
	padding: r(70px) 0 r(170px)
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0 r(80px)


.section-small
	padding: r(40px) 0

.section-grey
	background: #C4C4C4