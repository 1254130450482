.breadcrumb-wrapper
	border-bottom: 1px solid #E1E1E1
	.breadcrumb
		display: flex
		align-items: center
		height: 40px
		list-style: none
		padding-left: 0
		margin-bottom: 0
		li
			color: #666666
			a
				font-size: 14px
			+ li
				padding-left: 15px
				display: flex
				align-items: center
				&::before
					font-family: Linearicons-Free
					content: "\e876"
					margin-right: 15px
			&:first-child
				a
					font-size: 0
					&::before
						font-family: remixicon
						content: "\EE1D"
						font-size: 22px
			&:last-child
				a
					color: map-get($colors, blue)
