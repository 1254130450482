.career-detail-section
	padding: r(60px) 0 r(105px)

.career-detail-intro
	padding: r(40px) r(30px)
	background: #fff
	display: flex
	.intro-content
		padding-left: r(30px)
		flex: auto
		line-height: 1.375
		table
			width: 100%
			tr
				td
					padding: 8px 0
					&:nth-child(2n-1)
						font-weight: 500
						padding-right: 30px
	.career-detail-title
		padding-bottom: r(15px)
		border-bottom: 1px solid #E1E1E1
		font-size: r(30px)
		line-height: r(38px)
		color: color(main)
		margin-bottom: 8px
	@media screen and ( max-width: 1024.98px )
		display: block
		.img
			img
				width: 100%
		.intro-content
			padding-left: 0
			padding-top: r(30px)

.career-detail-content
	padding: r(30px)
	background: #fff
	margin-top: r(20px)
	.title
		font-weight: 500
		font-size: r(24px)
		line-height: r(30px)
		color: color(main)
		margin-bottom: r(15px)
	.content
		ul
			line-height: 1.5
			li
				text-indent: -1.6em
				padding-left: 1.6em
				&::before
					font-family: Linearicons-Free
					content: '\e87a'
					padding-right: 10px
					font-size: 12px

.career-detail-btn
	padding: r(30px) r(15px)
	background: #fff
	text-align: center
	> *
		width: 100%
		+ *
			margin-top: 1rem
	span
		font-size: r(14px)
		line-height: r(18px)
		display: block
		text-align: center

.career-detail-other
	margin-top: r(30px)
	background: #fff
	.other-title
		padding: r(12px) r(15px)
		margin-bottom: 0 
	.career-other-item
		padding: r(12px) r(15px)
		border-top: 1px solid #E1E1E1
		.title
			font-size: r(18px)
			line-height: r(22px)
		.date
			font-size: r(14px)
			line-height: r(18px)
			color: color(main)
			margin-top: r(10px)

.recruitment-detail-page 
	.fancybox-iframe
		width: 992px
.dialogpage
	margin: 0 auto
.apply-frm
	position: relative
	margin: 0
	background: #fff	
	line-height: 1.375
	padding: r(50px)
	input[type="file"]
		left: 0
	h4
		font-size: r(24px)
		color: color(main)
		font-weight: 700
		margin-bottom: r(24px)

	.row
		margin-left: r(-20px)
		margin-bottom: r(-20px)
		> *
			padding-left: r(20px)
			padding-bottom: r(20px)

	.fa-exclamation-triangle[style="display: inline;"]
		display: block !important
		top: 50%
		transform: translateY(-50%)
		right: 15px
		color: red
		position: absolute
	.label
		font-size: 14px
		font-weight: 400
		margin-bottom: 10px
		display: none
		.required
			display: inline-block
	.attachfile1
		.label
			display: inline-block
			.required
				display: inline
	.attachfile2
		margin-top: 15px
	input[type="text"], input[type="password"], textarea
		width: 100%
		border: 1px solid #c8c8c8
		padding: 0 r(20px)
		font-size: 1rem
		&::placeholder
			color: #999
	input[type="text"]
		height: r(53px)
	textarea
		height: r(153px)
		padding-top: r(12px)
	.RadAsyncUpload
		width: auto
		.ruDropZone
			display: none
		.ruFileWrap
			position: relative
			display: block
			height: 45px
		.ruFileInput, .ruBrowse
			width: r(190px)
			height: r(40px)
			background: color(light-blue)
			color: #fff
			text-transform: uppercase
			font-size: 14px
			display: flex
			justify-content: center
			align-items: center
			cursor: pointer
			border: 0
		.ruFileInput
			position: absolute
			top: 0
			opacity: 0

	h3
		font-weight: 700
		padding: 0 20px
		font-size: 18px

	.frm-btn
		text-align: center
		input[type="submit"]
			width: r(140px)
			height: r(45px)
			border: 0
			font-size: 14px
			font-weight: 700
			cursor: pointer
			background: color(main)
			color: #fff
	@media screen and ( max-width: 1024.98px )
		padding: r(30px)