.footer-top
	background: #E5E5E5
	padding: 40px 0
	h3
		font-weight: 500
		font-size: 24px
		line-height: 30px
	p
		line-height: 20px
		margin-top: 8px
	.footer-subscription
		display: flex
		align-items: center
		justify-content: center
		position: relative
	.subscription-img
		position: absolute
		left: 40px
		top: -81px
	.subscription
		padding-left: 250px
	.subscribe
		margin-top: r(28px)
	.subscribefrm
		display: flex
		position: relative
		.fa-exclamation-triangle
			position: absolute
			top: 100%
			padding-top: 5px
			left: 0
		input
			height: 50px
			width: 400px
			background: #FFFFFF
			padding: 0 r(15px)
			font-size: 14px
			border: 0
		button
			font-size: 18px
			color: #fff
			background: transparent
			border: 0
			flex: 0 0 50px
			height: 50px
			background: color(main)
			display: flex
			align-items: center
			justify-content: center
	.footer-social
		border-left: 1px solid #E1E1E1
		padding-left: 30px
		ul
			display: flex
			margin-top: r(28px)
			li
				a
					width: 50px
					height: 50px
					display: flex
					align-items: center
					justify-content: center
					border-radius: 20px
					font-size: 25px
					background: #fff
				+ li
					margin-left: 10px
	@media screen and ( max-width: 1280.98px )
		.subscription
			padding-left: 0
		.subscription-img
			display: none
		.footer-subscription
			justify-content: flex-start
	@media screen and ( max-width: 1024.98px )
		.subscribefrm
			input
				width: auto
				flex: auto
	@media screen and ( max-width: 768.98px )
		.footer-subscription
			display: block
		.footer-social
			padding-left: 0
			border-left: 0

.footer-bottom
	padding: r(50px) 0
	.footer-item
		color: #7F8084
		font-size: 14px
		line-height: 1.5
		+ .footer-item
			margin-top: 18px
	.footer-title
		font-weight: 600
		font-size: 18px
		line-height: 22px
		margin-bottom: 18px
		color: #333
	.footer-info
		p
			+ p
				margin-top: 10px
	.footer-link
		ul
			li
				+ li
					margin-top: 10px
	.footer-download
		a
			display: flex
			align-items: center
			span
				display: block
				padding-left: 5px
				em
					display: block
					font-size: 20px
					color: #333

.footer-copyright
	background: #F5F5F5
	padding: 20px 0
	font-size: 12px
	line-height: 20px
	color: #7F8084
	@media screen and ( max-width: 768.98px )
		.flex
			display: block !important
			text-align: center