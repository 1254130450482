.subnav-wrap
	padding: r(60px) 0 r(40px)
	background: #C4C4C4
	.subnav
		ul
			display: flex
			flex-wrap: wrap
			> *
				flex: auto
			li
				a
					background: #fff
					border: 1px solid #E1E1E1
					display: flex
					align-items: center
					justify-content: center
					text-align: center
					min-height: r(60px)
					font-size: r(18px)
					line-height: r(22px)
					transition: 0.3s all
					padding: 0 15px
				&.active,&:hover
					a
						background: color(main)
						color: #fff
						border-color: color(main)
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0
		.subnav
			ul
				flex-wrap: nowrap
				white-space: nowrap
				overflow-x: auto
				display: block
				letter-spacing: -8px
				li
					display: inline-block
					letter-spacing: normal
					width: auto
					a
						min-width: 200px
