.product-sidenav
	.sidenav-title
		font-weight: 600
		font-size: r(24px)
		line-height: r(30px)
		color: #fff
		text-transform: uppercase
		background: color(main)
		padding: r(15px) r(20px)

	.sidenav-list
		background: #fff

	.toggle-item-lv-1
		font-weight: 600
		font-size: r(18px)
		line-height: r(22px)
		display: flex
		align-items: center
		justify-content: space-between
		padding: r(15px) r(20px)
		border-bottom: 1px solid #E1E1E1
		> .trigger
			color: #666
			cursor: pointer
			transition: 0.3s all
		&.active
			color: color(main)
			> .trigger
				color: color(main)
				transform: rotate(180deg)

	.body-item-lv-1,.body-item-lv-2
		display: none

	.toggle-item-lv-2
		font-size: r(16px)
		line-height: r(22px)
		display: flex
		align-items: center
		justify-content: space-between
		padding: r(15px) r(20px)
		border-bottom: 1px solid #E1E1E1
		> .trigger
			color: #666
			cursor: pointer
		&.active
			color: color(main)
			> .trigger
				color: color(main)
				transform: rotate(180deg)

	.item-lv-3
		padding: r(15px) r(20px)
		ul
			background: #F5F5F5
			li
				a
					padding: r(12px) r(20px)
					font-size: r(13px)
					color: #666
					display: flex
					align-items: center
					line-height: 1.375
				+ li
					border-top: 1px solid #E1E1E1
.product-filter-wrap
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: r(30px)
	@media screen and ( max-width: 768.98px )
		display: block

.product-filter-list
	display: flex
	@media screen and ( max-width: 768.98px )
		margin-bottom: r(15px)
		> *
			width: 33.33%
			select
				width: 100% !important
	@media screen and ( max-width: 576px )
		margin-bottom: r(15px)
		flex-wrap: wrap
		> *
			width: 100%
			select
				width: 100% !important
			+ *
				margin-left: 0 !important
				margin-top: 10px

.filter-item
	+ .filter-item
		margin-left: 10px
	select
		background: #FFFFFF
		border: 1px solid #E1E1E1
		box-sizing: border-box
		border-radius: 4px
		height: r(50px)
		width: r(150px)
		padding: 0 15px
		font-size: 14px
		font-weight: 500
		appearance: none
		background-image: url(../img/sl.png)
		background-repeat: no-repeat
		background-position: 93% 50%

.product-sort
	display: flex
	align-items: center
	.title
		margin-right: r(20px)
	.filter-item
		select
			width: r(210px)
	@media screen and ( max-width: 1280.98px )
		.title
			margin-right: r(10px)
		.filter-item
			select
				width: r(150px)
	@media screen and ( max-width: 768.98px )
		.filter-item
			flex: auto
			select
				width: 100% !important

.product-item
	background: #fff
	border-radius: 8px
	overflow: hidden
	position: relative
	display: flex
	flex-direction: column
	.product-img
		padding-top: 100%
		position: relative
		img
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
	.product-icon
		position: absolute
		left: 1rem
		top: 1rem
		span
			width: r(40px)
			height: 20px
			border-radius: 4px
			color: #fff
			font-size: 14px
			padding: 4px 12px
	.product-caption
		margin: 0 r(20px)
		padding-top: r(16px)
		padding-bottom: r(25px)
		border-top: 1px solid #C4C4C4
		flex: 1 1 0%
		display: flex
		flex-direction: column
	.product-title
		font-weight: 600
		font-size: r(18px)
		line-height: r(22px)
		color: #5E5E5E
		&:hover
			color: color(main)
	.product-price
		font-size: r(15px)
		margin-top: 4px
		color: #666
		margin-bottom: r(24px)
	.product-btn-wrap
		margin-top: auto
		display: flex
		align-items: center
		justify-content: space-between
	.product-btn
		display: flex
		align-items: center
		.icon
			height: r(30px)
			width: r(30px)
			display: flex
			align-items: center
			justify-content: center
			border: 1px solid #7F8084
			transform: rotate(45deg)
			color: #7F8084
			transition: 0.3s all
			em
				transform: rotate(-45deg)
		.text
			font-size: 14px
			margin-left: r(10px)
			color: #7F8084
			line-height: 1.375
			transition: 0.3s all
	@media screen and ( min-width: 1024.98px )
		.product-btn
			&:hover
				.icon
					border-color: color(main)
					color: #fff
					background: color(main)
				.text
					color: color(main)
	@media screen and ( max-width: 576.98px )
		.product-btn-wrap
			display: block
			> *
				+ *
					margin-top: 20px

.product-list
	@media screen and ( max-width: 768.98px )
		.row
			margin-bottom: -10px
			margin-left: -10px
			> *
				padding-bottom: 10px
				padding-left: 10px

.product-slider
	.swiper-slide
		height: auto
	.product-item
		height: 100%