.cart-section
	.card-item
		+ .card-item
			margin-top: r(30px)
		.card-heading
			padding: r(20px) r(25px)
			border-bottom: 1px solid #ebebeb
			.card-title
				font-size: r(18px)
				color: color(main)
				text-transform: uppercase
				font-weight: 700
		.card-body
			padding: r(25px)
			line-height: 1.375

	input[type="checkbox"]
		appearance: none
		position: relative
		display: inline-block
		height: 13px
		width: 13px
		margin: 0
		background-image: url('../img/checkbox.png')
		background-repeat: no-repeat
		background-size: contain
		&:checked
			background-image: url('../img/checkbox-checked.png')

.cart-inner
	.card-title 
		a
			display: none

.cart-product-list
	.cart-product-list-heading
		background: #f3f3f3
		padding: r(15px)
		border-radius: 5px

.cart-product-item
	padding: 20px 15px
	+ .cart-product-item
		border-top: 1px solid #eeeeee
	.cart-product-item-img
		width: r(110px)
	.cart-product-item-name
		display: block
		flex: 0 0 50%
	.cart-product-item-content
		flex: 1
		line-height: 1.375
		margin-left: 1rem
	.cart-product-item-caption,.cart-product-item-price
		width: 50%
	.cart-product-item-content-left,.cart-product-item-detail
		flex: 1
	.cart-product-item-amount
		text-align: right
		margin-left: 1rem
		.spin-input-wrap
			border-radius: 5px

.cart-add-wishlist
	&.active
		span:before
			content: "\EE0E"

@media screen and ( max-width: 768.98px )
	.card-item
		.card-body
			padding: 0
	.cart-product-item
		flex-wrap: wrap
		padding: 15px 0
		.cart-product-item-amount
			flex: 100%
			text-align: left
			margin-left: 0
			margin-top: 0.75rem
		.cart-product-item-caption,.cart-product-item-price
			width: 100%
		.cart-product-item-price 
			text-align: left
			margin-top: 0.5rem
			p
				justify-content: flex-start
				text-align: left !important
		.cart-product-item-content-bottom
			p
				text-align-last: left
		.cart-product-item-name
			flex: 0 0 100%

.cart-order-info
	ul
		li
			+ li
				margin-top: 0.75rem

	hr
		overflow: visible
		text-align: inherit
		margin: r(20px) 0
		border: 0
		border-top: 1px solid #e5e5e5

.cart-coupon-input
	label
		display: block
		margin-bottom: 0.75rem
	.form-inline
		display: flex
		border-radius: 5px
		overflow: hidden
		input
			height: r(40px)
			border: 1px solid #e1e1e1
			flex: 1
		button,a
			display: flex
			align-items: center
			justify-content: center
			width: r(110px)
			height: r(40px)
			background-color: color(main)
			color: #fff
			border: 0
			text-transform: uppercase
			font-weight: 700
			letter-spacing: 0.01rem

.cart-coupon-note
	* + *
		margin-top: r(15px)

.cart-group-button
	justify-content: flex-end
	margin: r(-15px)
	display: flex
	.btn
		margin: r(15px)
		height: 40px
		min-width: r(260px)
		border-radius: 20px
		box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05)
	@media screen and ( max-width: 576px )
		flex-wrap: wrap
		.btn
			width: 100%

.cart-page
	@media screen and ( max-width: 768.98px )
		header
			display: none
		.breadcrumb-wrapper
			display: none
		.cart-section
			padding: 0
			overflow: hidden
			.container
				max-width: none
				padding: 0
			.row
				margin-bottom: 0
				margin-left: 0
				> *
					padding-bottom: 0
					padding-left: 0
		.card-item
			border-radius: 0
			box-shadow: none
			border-top: 5px solid #e5e5e5
			+ .card-item
				margin-top: 0
			.card-heading
				border-bottom: 0
				padding-bottom: 0
				padding-left: 15px
				padding-right: 15px
			.card-body
				padding: 15px

		.cart-inner
			border-top: 0
			.card-heading
				background: map-get($colors, main)
				padding-bottom: 1.25rem
				.card-title
					color: #fff !important
					text-align: center
					position: relative
					a
						display: block
						position: absolute
						font-size: 24px
						top: 50%
						transform: translateY(-50%)
			.card-body
				padding: 0

		.cart-group-button
			padding: 20px 0 30px
			justify-content: center
			.btn-back
				display: none

		.cart-aside
			.cart-order-info
				order: 2
				hr
					margin-left: -15px
					margin-right: -15px
					border-top: 5px solid #e5e5e5
				.total-price
					margin-top: r(80px)
					justify-content: flex-end
					align-items: baseline
					strong
						font-size: 14px
						font-weight: 400
					.text-main
						font-size: 24px
						margin-left: 20px
						font-weight: 700
			.cart-coupon
				order: 1
