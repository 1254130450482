.news-item
	display: block
	position: relative
	&::after
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.07) 100%)
	.news-img
		+img-scale(100%)
		overflow: hidden
		img
			transition: 0.3s all
	.news-caption
		position: absolute
		bottom: r(28px)
		width: 100%
		left: 0
		text-align: center
		padding: 0 15px
		color: #fff
		z-index: 2
		.news-date
			font-size: 14px
			margin-bottom: r(18px)
		.news-title
			font-weight: 500
			font-size: 16px
			line-height: 1.5
			+line(2)
	@media screen and ( min-width: 1024.98px )
		&:hover
			.news-img
				img
					transform: scale(1.05)