.news-detail-title
	font-size: r(30px)
	line-height: r(38px)
	margin-bottom: r(30px)

.news-detail-meta
	display: flex
	align-items: center
	justify-content: space-between
	padding: 12px 0
	border-top: 1px solid #E1E1E1
	border-bottom: 1px solid #E1E1E1
	.date
		font-size: 12px

.news-detail-content
	padding-top: r(25px)

.other-title
	font-weight: 600
	font-size: r(24px)
	line-height: r(30px)
	margin-bottom: r(15px)

.news-other
	padding: r(20px) r(30px)
	background: #F5F5F5

.news-other-list
	padding-top: r(20px)
	border-top: 1px solid #E1E1E1

.news-other-item
	display: flex
	+ .news-other-item
		padding-top: r(20px)
		margin-top: r(20px)
		border-top: 1px solid #E1E1E1
	.img
		flex: 0 0 r(110px)
		height: 66px
		img
			width: 100%
			height: 100%
			object-fit: cover
	.caption
		padding-left: r(15px)
		.date
			font-size: 12px
			margin-bottom: 8px
		.title
			font-size: 14px
			line-height: 19px
			+line(2)
