.gallery-list
	.row
		margin-bottom: -10px
		margin-left: -10px
		> *
			padding-bottom: 10px
			padding-left: 10px
	
	
.gallery-item
	display: block
	position: relative
	&::after
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.07) 100%)
	.gallery-img
		+img-scale(345 / 610 * 100%)
	.gallery-title
		position: absolute
		bottom: r(28px)
		width: 100%
		left: 0
		text-align: center
		padding: 0 15px
		color: #fff
		z-index: 2
		font-weight: 500
		font-size: 16px
		line-height: 1.5
		+line(2)
