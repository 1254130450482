.home-5
	.section-title
		color: #7f8084
	.content
		padding: r(40px) r(40px) r(40px) 0
	.content-wrap
		position: relative
		&::before
			content: ''
			position: absolute
			height: 100%
			width: 50%
			background: #e5e5e5
			top: 0
			left: 0
			z-index: -1
	.title
		font-size: r(24px)
		line-height: 1.375
		font-weight: 600
		color: #7f8084
		padding-left: r(40px)
		position: relative
		&::before
			content: ''
			position: absolute
			width: 5px
			height: 30px
			background: #b2b2b4
			top: 0
			left: 0
	.desc
		padding-left: r(40px)
		line-height: 1.375
		font-size: 14px
		margin-top: r(24px)
	.img
		img
			width: 100%
	@media screen and ( max-width: 1024.98px )
		.content
			padding: 0
			margin-bottom: r(30px)
		.content-wrap
			&::before
				display: none