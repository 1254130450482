.home-4
	.project-home
		padding: r(50px) 0
		background: #7f8084
		h3
			font-size: r(36px)
			font-weight: 700
			line-height: 1.375
			color: #fff
			margin-bottom: 1rem
	.item
		display: block
		position: relative
		.img
			+img-scale(550 / 840 * 100%)
			&:before
				content: ''
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: rgba(#000 , 0.3)
				position: absolute
				z-index: 2
		.caption
			position: absolute
			bottom: r(40px)
			left: 0
			padding: 0 r(60px)
			display: flex
			line-height: 1.375
			color: #fff
			width: 100%
			z-index: 3
		.title
			font-weight: 600
			font-size: r(22px)
			flex: 0 0 40%
		.desc
			flex: auto
			padding-left: r(30px)
			transition: 0.3s all
		&:hover
			.title
				text-decoration: underline
	.swiper-navigation
		display: flex
		position: absolute
		right: 15%
		top: 50%
		transform: translateY(-50%)
		z-index: 2
		.swiper-btn
			position: static
			background: #fff
			transform: none
	@media screen and ( min-width: 1024.98px )
		padding-bottom: r(180px) !important
		.project-home-slider
			margin-bottom: -120px
	@media screen and ( max-width: 1024.98px )
		.item
			.caption
				padding: 20px
				position: static
				background: #fff
				color: #333
				display: block
				.title
					margin-bottom: 15px
				.desc
					padding-left: 0