.checkout-complete-section
	padding: 40px 0
	.card-item
		.card-heading
			padding: r(20px) r(25px)
			border-bottom: 1px solid #ebebeb
			.card-title
				font-size: r(18px)
				color: #2aba2a
				text-transform: uppercase
				display: flex
				align-items: center
				.text
					font-weight: 700
					margin-left: 20px
		.card-body
			padding: r(50px)

	.cart-group-button
		justify-content: flex-start
		margin: 0
		margin-top: r(30px)
		.btn
			margin: 0

.checkout-complete-page
	@media screen and ( max-width: 768.99px )
		.breadcrumb-wrapper
			border-bottom: 1px solid #eeeeee
			.container
				max-width: none
		.checkout-complete-section
			padding: 0 !important
			.container
				padding: 0
				max-width: none
			.row
				margin-bottom: 0
				margin-left: 0
				> *
					padding-bottom: 0
					padding-left: 0
			.card-item
				border-radius: 0
				box-shadow: none
				.card-heading
					border-bottom: 0
					padding-bottom: 0
					padding-left: 15px
					padding-right: 15px
				.card-body
					padding: 20px 15px
			.img
				margin-bottom: r(25px)
			.btn-next
				width: 100%