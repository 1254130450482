.project-category-item
	display: block
	position: relative
	&::after
		content: ''
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
	.img
		+img-scale(900 / 480 * 100%)
	.caption
		position: absolute
		top: 50%
		left: 50%
		width: 100%
		transform: translate(-50%,-50%)
		color: #fff
		padding: 0 15px
		text-align: center
		z-index: 2
	.title
		font-size: r(36px)
		line-height: r(45px)
		margin-top: r(38px)
