.view-more
	display: inline-flex
	align-items: center
	font-weight: 700
	position: relative
	color: #666
	span
		font-size: r(16px)
		margin-left: 6px
	&::before
		content: url('../img/view-more-icon.png')
		position: absolute
		top: -33px
		left: -30px

.btn-wrap
	margin-top: r(70px)

.swiper-btn
	background: #F5F5F5
	display: flex
	align-items: center
	justify-content: center
	width: r(60px)
	height: r(60px)
	font-size: r(20px)
	border-radius: r(25px)
	color: #7F8084
	position: absolute
	top: 50%
	transform: translateY(-50%)
	cursor: pointer
	z-index: 2
	@media screen and ( min-width: 1024.98px )
		margin: 0 15px
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%
	@media screen and ( max-width: 1024.98px )
		display: none
		&.swiper-prev
			left: -5px
		&.swiper-next
			right: -5px

.btn
	display: inline-flex
	align-items: center
	justify-content: center
	height: r(50px)
	border-radius: 4px
	color: #fff
	font-weight: 600
	font-size: r(14px)
	line-height: r(18px)
	text-align: center
	text-transform: uppercase
	padding: 0 15px

.spin-input-wrap
	display: inline-flex
	height: 40px
	border-radius: 20px
	overflow: hidden
	width: r(140px)
	.btn-spin,.spin-btn
		width: r(60px)
		display: flex
		align-items: center
		justify-content: center
		background-color: #f8f8f8
		cursor: pointer
		color: #c3c3c3
	input
		width: r(40px)
		text-align: center
		border: 0
		font-size: 16px
		background-color: #f8f8f8