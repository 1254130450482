.faq-section
	padding-top: 0 !important
	.faq-item
		background: #fff
		+ .faq-item
			margin-top: 10px
		.heading
			padding: r(10px) r(30px)
			display: flex
			align-items: center
			transition: 0.3s all
			cursor: pointer
			&.active
				color: color(main)
				.arrow
					transform: rotate(180deg)
		.number
			font-weight: 600
			font-size: r(24px)
			line-height: r(30px)
			margin-right: r(30px)
		.title
			font-size: r(18px)
			line-height: 1.5
		.arrow
			margin-left: auto
			transition: 0.3s all
		.body
			padding: r(25px) r(30px)
			border-top: 1px solid #E1E1E1
			display: none
	@media screen and ( max-width: 768.98px )
		.faq-item
			.heading
				padding: r(10px) r(15px)
			.body
				padding: r(15px)