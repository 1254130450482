//Reset
@import src/_plugins/sass-libraries/reset.sass

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	font-size: 14px
	line-height: 1
	font-family: 'Lexend', sans-serif
	color: #333
	@media (min-width: 1280px)
		font-size: 16px

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none
	
.fb_iframe_widget
	height: 20px

.fb-like
	span
		width: 128px !important
		height: 20px !important
		iframe
			width: 128px !important
			height: 20px !important

.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Montserrat', sans-serif
	&:before
		font-family: 'Font Awesome 5 Pro'

.frm-btn-reset
	display: none !important

.news-detail-page
	.sub-banner
		display: none

.recruitment-detail-page
	.career-section-3
		padding: 0 !important

.product-contact-section
	display: none

.product-detail-page
	.product-contact-section
		display: block
