.home-3
	margin-top: 10px
	.item
		display: block
		width: r(150px)
		margin: 0 auto
		position: relative
		.img
			width: r(150px)
			height: r(150px)
			border-radius: 50%
			position: relative
			background: #fff
			overflow: hidden
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				width: 90%
		.title
			position: absolute
			font-size: 16px
			font-weight: 700
			color: #7f8084
			left: 50%
			transform: translateX(-50%)
			bottom: -2px
			white-space: nowrap
			text-transform: uppercase
	@media screen and ( min-width: 1024.98px )
		.row
			margin-bottom: r(-60px)
			> *
				padding-bottom: r(60px)