.contact-section
	padding: r(40px) 0
	background: #e5e5e5
	.ModuleContent
		height: 100%
	.contact-info
		padding: r(40px) r(30px)
		background: rgba(#fff , 0.6)
		height: 100%
		h2
			font-weight: 600
			font-size: r(18px)
			line-height: r(22px)
			margin-bottom: r(13px)
		ul
			li
				display: flex
				font-size: r(14px)
				line-height: 1.5
				+ li
					margin-top: r(10px)
				span
					margin-right: 8px
					font-size: r(12px)
					position: relative
					top: 4px

	@media screen and ( min-width: 1024.98px )
		min-height: 723px
		background: url(../img/contact-bg.png)
		background-repeat: no-repeat
		background-size: cover
		background-position: center center
		padding-top: 114px

.wrap-form
	.row
		margin-bottom: -20px
		margin-left: -20px
		> *
			padding-bottom: 20px
			padding-left: 20px
.form-group
	input[type="text"],	textarea, select
		border: 1px solid #E1E1E1
		height: r(50px)
		width: 100%
		background: rgba(#fff , 0.6)
		padding: 0 r(20px)
		font-size: 14px
	&::placeholder
		color: #7F8084
	textarea
		height: 145px
		padding: r(16px) r(20px)
.frm-btn
	margin-top: r(20px)
	input[type="submit"]
		width: 128px
		height: 50px
		border-radius: 4px
		color: #fff
		background: color(main)
		text-transform: uppercase
		font-size: 14px
		border: 0

.contact-map-section
	.map
		iframe
			width: 100%
			height: r(690px)
	@media screen and ( max-width: 1024.98px )
		.map
			iframe
				height: r(400px)
