.career-section-1
	position: relative
	.img
		img
			width: 100%
	&::after
		position: absolute
		content: ''
		right: 0
		bottom: r(30px)
		height: 185px
		width: 345 / 1920 * 100%
		background: color(main)
		z-index: -2
	&::before
		position: absolute
		content: ''
		right: 0
		bottom: r(30px)
		height: 20px
		width: 100%
		background: color(main)
		z-index: -1
	@media screen and ( max-width: 1024.98px )
		padding-top: r(40px)

.career-section-2
	background: url(../img/career-bg.png)
	padding: r(40px) 0
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	.item
		color: #fff
		text-align: center
		.title
			margin-top: r(20px)
			font-weight: 600
			font-size: r(18px)
			line-height: r(22px)
		.brief
			font-size: r(14px)
			line-height: r(18px)
			margin-top: r(12px)
	@media screen and ( min-width: 1024.98px )
		min-height: 550px
		padding: r(80px) 0

.career-list
	table
		width: 100%
		border: 1px solid #E1E1E1
		tr
			th
				background: #333333
				color: #fff
				font-weight: 600
				font-size: r(18px)
				line-height: r(22px)
			th,td
				padding: 15px
			&:nth-child(2n-11)
				background: #F5F5F5
	@media screen and ( max-width: 768.98px )
		overflow-x: auto
		table
			width: 150%