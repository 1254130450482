.about-1
	position: relative
	&::after
		position: absolute
		content: ''
		right: 0
		top: 0
		height: 100%
		width: calc(50% - 15px)
		background: #F5F5F5
		z-index: -2
	&::before
		position: absolute
		content: ''
		right: 0
		bottom: 0
		height: 325px
		width: calc(50% - 15px)
		background: color(main)
		z-index: -1
	@media screen and ( min-width: 1024.98px )
		padding-bottom: r(148px)
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0
		&::after
			display: none
		&::before
			width: 100%
			height: 150px

.about-2
	background-image: url(../img/about-bg-1.png)
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	.history-year-slider
		.year-item
			font-weight: 600
			font-size: r(30px)
			line-height: r(38px)
			color: #666
			padding-bottom: 11px
			border-bottom: 1px solid #C4C4C4
			transition: 0.3s all
		.swiper-slide-active
			.year-item
				color: color(main)
				border-bottom: 1px solid color(main)
		.swiper-navigation
			display: flex
			position: absolute
			right: 0
			top: 0
			transform: translateY(-50%)
			z-index: 2
			.swiper-btn
				position: static
				background: #fff
				transform: none
	.history-content-slider
		margin-top: r(40px)
		.swiper-slide
			height: auto
		.content-item
			background: #fff
			padding: r(20px)
			height: 100%
			.img
				+img-scale(215 / 350 * 100%)
			.title
				color: #666
				font-weight: 600
				font-size: r(18px)
				line-height: r(22px)
				margin-top: r(22px)
				margin-bottom: 9px
				transition: 0.3s all
			.brief
				font-size: r(14px)
				line-height: r(18px)
		.swiper-slide-active
			.content-item
				.title
					color: color(main)
	@media screen and ( max-width: 1024.98px )
		.history-wrap
			padding: 0 15px

.about-3
	background-image: url(../img/about-bg-2.png)
	background-size: cover
	background-repeat: no-repeat
	background-position: bottom center
	.swiper-slide
		height: auto
	.item
		height: 100%
		display: flex
		flex-direction: column
		.img
			+img-scale(400 / 600 * 100%)
		.content
			padding: r(40px)
			background: #fff
			border-bottom: 4px solid color(main)
			position: relative
			margin-left: r(60px)
			top: r(-60px)
			flex: 1 1 0%
		.title
			margin-bottom: r(12px) !important
	@media screen and ( min-width: 1024.98px )
		padding-top: r(40px)
		padding-bottom: r(300px) !important
	@media screen and ( max-width: 1024.98px )
		.item
			.content
				margin-left: 0
				top: 0

.about-4
	padding-top: r(70px)

	.staff-item
		.img
			+img-scale(354 / 315 * 100%)
		.title
			font-weight: 600
			font-size: r(24px)
			line-height: r(30px)
			margin-top: r(20px)
		.subtitle
			font-size: r(18px)
			line-height: r(22px)
			margin-top: r(8px)
			color: #7F8084
		&.first
			.img
				padding-top: 474 / 420 * 100%
	@media screen and ( min-width: 1024.98px )
		.staff-list-wrap
			.row
				margin-left: r(-75px)
				margin-bottom: r(-75px)
				> *
					padding-left: r(75px)
					padding-bottom: r(75px)
			.staff-col
				width: 23.25%
				&.staff-col-first
					width: 30%
	@media screen and ( max-width: 1024.98px )
		padding-top: r(40px)
		.staff-list-wrap
			padding: 0 15px
			.row
				margin-left: r(-15px)
				margin-bottom: r(-15px)
				> *
					padding-left: r(15px)
					padding-bottom: r(15px)
			.staff-col
				width: 50%

.about-5
	padding: r(40px) 0

	.partner-slider
		background: #FFFFFF
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05)
		padding: r(15px)
		.swiper-slide[data-swiper-row="1"]
			padding-top: r(15px)
			border-top: 1px solid #C4C4C4
			margin-top: r(15px) !important
		.item
			text-align: center
			height: 100px
			position: relative
			border-right: 1px solid #C4C4C4
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
		.swiper-btn
			border-radius: 50%
			width: 50px
			height: 50px
			border: 1px solid #007733
			background: #fff
			margin: 0
			&.swiper-prev
				right: auto
				left: -25px
			&.swiper-next
				right: -25px
				left: auto
	@media screen and ( min-width: 1024.98px )
		padding-top: r(160px)
		padding-bottom: r(450px)
		background-image: url(../img/about-bg-3.png)
		background-size: cover
		background-repeat: no-repeat
		background-position: bottom center
		.section-desc
			padding-right: r(40px)
		.partner-slider
			padding: r(30px)
		.swiper-slide[data-swiper-row="1"]
			padding-top: r(20px)
			margin-top: r(20px) !important
			border-top: 1px solid #C4C4C4
