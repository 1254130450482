.project-item
	display: flex
	flex-direction: column
	background: #7F8084
	.project-img
		+img-scale(220 / 390 * 100%)
	.project-caption
		flex: 1 1 0%
		padding: r(20px)
		text-align: center
		color: #fff
	.project-title
		font-weight: 600
		font-size: r(18px)
		line-height: r(22px)
	.project-subtitle
		font-size: r(14px)
		line-height: r(18px)
		margin-top: r(12px)

.project-list-section
	+ .project-list-section
		border-top: r(20px) solid #C4C4C4
