.project-detail-title
	font-size: r(30px)
	line-height: r(38px)
	text-align: center
	margin-bottom: r(30px)

.project-detail-info
	table
		width: 100%
		line-height: 1.5
		tr
			border: 1px solid #E1E1E1
			td
				padding: r(12px) r(30px)
				&:first-child
					font-weight: 600
					background: #F5F5F5
	@media screen and ( max-width: 1024.98px )
		table
			tr
				td
					padding: r(12px)

.project-detail-images
	margin-top: r(60px)

.project-detail-images-slider
	.img
		+img-scale(520 / 915 * 100%)

.project-detail-thumb-slider
	margin: -2px 0
	.img
		height: 100%
		transition: 0.3s all
		border: 2px solid transparent
		img
			width: 100%
			height: 100%
			object-fit: cover
	.swiper-slide-thumb-active
		.img
			border: 2px solid color(main)
	.swiper-container
		height: 520px
	.swiper-btn-2
		position: absolute
		left: 50%
		transform: translateX(-50%)
		font-size: r(36px)
		cursor: pointer
		&.swiper-prev
			bottom: 100%
		&.swiper-next
			top: 100%
	@media screen and ( max-width: 1024.98px )
		.swiper-container
			height: auto
		.swiper-navigation
			display: none

.project-detail-other
	border-top: r(20px) solid #C4C4C4
