.section-title
	font-size: r(36px)
	line-height: 1.375
	font-weight: 700
	color: color(main)
	margin-bottom: r(30px)

.small-title
	font-size: r(30px)
	line-height: r(38px)
	margin-bottom: r(30px)
