.home-banner
    position: relative
    .swiper-pagination-wrap
        position: absolute
        width: 100%
        left: 0
        bottom: r(40px)
    .swiper-pagination
        position: absolute
        display: flex
    .swiper-pagination-bullet
        width: 12px
        height: 12px
        transform: rotate(45deg)
        border: 3px solid #fff
        border-radius: 0
        opacity: 1
        background: transparent
        + .swiper-pagination-bullet
            margin-left: 10px
        &.swiper-pagination-bullet-active
            background: color(main)
            border-color: color(main)
    .banner
        position: relative
        .img
            height: 720px
            img
                width: 100%
                height: 100%
                object-fit: cover
        .caption
            position: absolute
            top: 50%
            transform: translateY(-50%)
            width: 100%
            color: #fff
        .title
            font-size: r(18px)
            line-height: 1.375
        .desc
            font-size: r(28px)
            font-weight: 700
            line-height: 1.375
            margin-top: r(12px)
        .view-more
            color: #fff
            margin-left: r(30px)
            margin-top: r(50px)
    @media screen and ( max-width: 1024.98px )
        .banner
            .img
                height: 320px