.service-item
	position: relative
	.service-img
		+img-scale(520 / 925 * 100%)
	.service-caption
		position: relative
		margin-left: -125px
		background: #fff
		padding: r(60px) r(40px)
	.service-title
		font-weight: 600
		font-size: r(30px)
		line-height: r(38px)
		text-transform: uppercase
		color: color(main)
		margin-bottom: r(20px)
	.service-brief
		line-height: r(20px)
	.view-more
		margin-left: r(30px)
		margin-top: r(60px)
	+ .service-item
		margin-top: r(60px)
	&:nth-child(2n)
		.row
			flex-direction: row-reverse
		.service-caption
			margin-left: 0
			margin-right: -125px
	@media screen and ( max-width: 1024.98px )
		.service-caption
			margin: 0 !important
			padding: r(30px)