.service-detail-desc-wrap
	padding: r(80px) 0
	position: relative
	&::after
		position: absolute
		content: ''
		right: 0
		top: 0
		height: 100%
		width: 31.25%
		background: color(main)
		z-index: -1
	@media screen and ( min-width: 1024.98px )
		.service-detail-desc
			padding-right: r(80px)
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0
		&::after
			top: auto
			bottom: 0
			height: 31.25%
			width: 100%

.service-detail-info
	border-top: 20px solid #C4C4C4
	padding: r(72px) 0 r(140px)
	.title
		font-size: r(30px)
		line-height: r(38px)
		margin-bottom: r(25px)
	.content
		display: flex
		flex-wrap: wrap
		margin-left: -20px
		margin-bottom: -20px
		> *
			width: 33.333%
			padding-left: 20px
			padding-bottom: 20px
		ul
			line-height: 1.5
			li
				text-indent: -1.6em
				padding-left: 1.6em
				&::before
					font-family: Linearicons-Free
					content: '\e87a'
					padding-right: 10px
					font-size: 12px
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0
		.content
			> *
				width: 50%
	@media screen and ( max-width: 576px )
		.content
			> *
				width: 100%