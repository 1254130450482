.stepper-wrapper
	margin-top: auto
	display: flex
	justify-content: space-between
	margin-top: r(30px)

.stepper-item
	position: relative
	display: flex
	flex-direction: column
	align-items: center
	flex: 1

@media (max-width: 768px)
	font-size: 12px

.stepper-item::before
	position: absolute
	content: ""
	border-bottom: 2px solid #ccc
	width: 100%
	top: 20px
	left: -50%
	z-index: 2

.stepper-item::after
	position: absolute
	content: ""
	border-bottom: 2px solid #ccc
	width: 100%
	top: 20px
	left: 50%
	z-index: 2

.stepper-item .step-counter
	position: relative
	z-index: 5
	display: flex
	justify-content: center
	align-items: center
	width: 40px
	height: 40px
	border-radius: 50%
	background: #fff
	margin-bottom: 6px

.stepper-item.active
	font-weight: bold

.stepper-item.completed .step-counter
	background-color: color(main)
	color: #fff

.stepper-item.completed::after
	position: absolute
	content: ""
	border-bottom: 2px solid color(main)
	width: 100%
	top: 20px
	left: 50%
	z-index: 3

.stepper-item:first-child::before
	content: none

.stepper-item:last-child::after
	content: none
