.modulepager
	margin-top: r(40px)
	.pagination
		display: flex
		align-items: center
		justify-content: center
		li
			transform: rotate(45deg)
			width: r(40px)
			height: r(40px)
			border: 1px solid #E1E1E1
			justify-content: center
			align-items: center
			display: flex
			background: #fff
			+ li
				margin-left: r(24px)
			a,span
				text-decoration: none
				transform: rotate(-45deg)
			&.active,&:hover
				border-color: color(main)
				color: #fff
				background: color(main)
			.FirstPage,.LastPage,.BackPage,.NextPage
				display: none !important
			// .BackPage
			// 	font-size: 0
			// 	&:before
			// 		font-family: remixicon!important
			// 		content: "\EA64"
			// 		font-size: 15px
			// .NextPage
			// 	font-size: 0
			// 	&:before
			// 		font-family: remixicon!important
			// 		font-size: 15px
			// 		content: "\EA6E"
