.checkout-login
	width: r(500px)
	max-width: 100%
	background: #fff
	border-radius: 10px
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05)
	margin: 0 auto

	.login-heading 
		padding: r(20px) r(25px)
		border-bottom: 1px solid #ebebeb
		font-size: r(18px)
		text-transform: uppercase
		font-weight: 700

.checkout-login-form
	padding: r(25px)

	.form-link
		display: flex
		justify-content: space-between

	.register-link
		color: #0000EE
		text-decoration: underline

		

