.product-detail-top,.product-detail-bottom
	padding: r(30px)
	background: #fff

.product-detail-bottom
	margin-top: r(60px)

.product-detail-images
	position: relative
	.img
		height: r(376px)
		position: relative
		display: block
		img
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			max-height: 100%
	@media screen and ( max-width: 768.98px )
		.img
			height: r(350px)

.product-detail-thumbnail
	max-width: 400px
	margin: 10px auto 0
	.img
		border: 1px solid #fff
		transition: 0.3s all
		padding-top: 100%
		position: relative
		img
			position: absolute
			left: 50%
			top: 50%
			transform: translate(-50%,-50%)
			width: 90%
	.swiper-slide-thumb-active
		.img
			border-color: #FFC10D

.product-detail-title
	margin-bottom: r(4px) !important

.product-detail-price
	margin-bottom: r(10px)
	font-size: r(20px)

.product-detail-heading
	padding: r(30px) r(20px)
	background: #F5F5F5
	border-radius: 4px
	margin-bottom: r(20px)

.product-detail-desc
	padding-bottom: r(20px)
	border-bottom: 1px solid #EBE5DF
	margin-bottom: r(20px)

.product-detail-meta
	font-size: 12px
	ul
		li
			display: inline-block
			+ li
				margin-left: 12px
				&::before
					content: '|'
					margin-right: 12px
	@media screen and ( max-width: 1280.98px )
		ul
			li
				display: block
				line-height: 1.375
				+ li
					margin-left: 0
					&::before
						display: none

.product-detail-info-wrap
	@media screen and ( max-width: 1280.98px )
		display: block !important

.product-detail-info-left
	flex: 0 0 300px

.product-detail-info-right
	flex: auto
	padding-left: r(20px)
	@media screen and ( max-width: 1280.98px )
		padding-left: 0

.product-detail-properties
	padding-bottom: r(20px)
	border-bottom: 1px solid #EBE5DF
	margin-bottom: r(20px)
	> *
		width: 50%

.product-detail-color
	padding-left: r(25px)
	border-left: 1px solid #EBE5DF
	ul
		list-style: none
		padding-left: 0 !important
		margin-left: -10px
		display: flex
		align-items: center
		li
			display: inline-block
			margin-left: 10px
			transition: 0.3s all
			a
				display: block
				width: 22px
				height: 22px
				border-radius: 50%
				transition: 0.3s all
			+ li
				margin-top: 0
			&.active
				padding: 3px
				border-style: solid
				border-width: 1px
				border-radius: 50%
				a
					width: 16px
					height: 16px

.product-detail-policy
	padding: r(20px)
	box-shadow: -1px 3px 25px rgba(0, 0, 0, 0.06)
	border-radius: 4px
	ul
		li
			display: flex
			.icon
				flex: 0 0 35px
			.text
				flex: auto
				padding-left: r(16px)
				font-size: 12px
				line-height: 15px
			+ li
				padding-top: 1rem
				margin-top: 1rem
				border-top: 1px solid #EBE5DF

.product-detail-contact
	width: r(355px)
	max-width: 100%
	.btn
		width: 100%
		span
			font-size: r(24px)
			margin-right: r(13px)
	.contact-note
		margin-top: 1rem
		text-align: center
		line-height: 1.375
		font-size: 14px
	@media screen and ( max-width: 1280.98px )
		width: 100%
		margin-top: r(20px)

.product-detail-attr
	.tabs-nav
		background: #F5F5F5
		display: inline-block
		overflow: hidden
		border-radius: 4px
		li
			display: inline-block
			a
				min-width: r(180px)
				height: r(50px)
				display: flex
				align-items: center
				justify-content: center
				font-size: r(14px)
				transition: 0.3s all
				letter-spacing: initial
				padding: 0 10px
			&.active
				a
					background: color(main)
					color: #fff
	.tab-content
		padding-top: r(20px)
		.small-title
			margin-bottom: r(16px) !important
		.expand-content
			max-height: 300px
		.expand-toggle 
			margin-top: r(50px)
			margin-left: r(30px)
	@media screen and ( max-width: 1024.98px )
		.tabs-nav
			white-space: nowrap
			overflow: auto
			max-width: 100%