header
	background: #fff
	
.header-top-wrap
	padding: 10px 0
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05)

.header-top
	@media screen and ( max-width: 1024.98px )
		flex-wrap: wrap
		.logo
			flex: 0 0 120px
			order: 0
		.menu-toggle
			flex: 0 0 40px
			order: 1
		.search-wrapper
			flex: 0 0 100%
			order: 2
			display: none

.search-wrapper
	padding-left: r(45px)
	.searchbox
		width: 100%
		position: relative
		input
			height: 47px
			border: 1px solid #7F8084
			padding: 0 20px
			border-radius: 50px
			min-width: 400px
			&::placeholder
				color: #999
		button
			height: 40px
			width: 40px
			background: #7F8084
			border-radius: 50%
			border: 0
			color: #fff
			font-size: 22px
			display: flex
			align-items: center
			justify-content: center
			cursor: pointer
			position: absolute
			right: 4px
			top: 50%
			transform: translateY(-50%)

	.suggestsearch
		position: absolute
		top: 100%
		padding: 20px
		background: #fff
		border-radius: 10px
		border: 1px solid #eeeeee
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15)
		margin-top: 10px
		z-index: 10
		display: none
		width: 100%
		max-height: 70vh
		overflow: auto
		line-height: 1.5
		.row
			flex-wrap: wrap !important
		&::-webkit-scrollbar
			width: 5px
			background: #ccc
		&::-webkit-scrollbar-thumb
			background: color(main)
		.keyword-list
			line-height: 1.375
			ul
				li
				+ li
					margin-top: 10px
					a
						font-size: r(15px)
						color: #666
						.img
							display: flex
							align-items: center
							justify-content: center
							img
								max-height: 40px
	@media screen and ( max-width: 1024.98px )
		padding: 10px 0 0
		.searchbox
			input
				width: 100%
				min-width: auto

.header-top-right
	> * + *
		margin-left: r(30px)
	@media screen and ( max-width: 1024.98px )
		> * + *
			margin-left: r(15px)

.hotline
	display: flex
	align-items: center
	height: r(45px)
	padding: 0 12px
	background: color(main)
	color: #fff
	border-radius: 100px
	span
		font-size: r(24px)
		margin-right: 8px
	strong
		margin: 0 2.5px

.header-bottom-wrap
	background: #F4F4F4
	@media screen and ( max-width: 1024.98px )
		background: transparent
		.container
			padding: 0
		.header-bottom
			display: block !important
		.util-wrapper
			margin-top: 24px

.menu
	> ul
		display: flex
		> li
			> a
				display: flex
				align-items: center
				min-height: 49px
				font-weight: 500
				font-size: 16px
				position: relative
				&:after
					content: ''
					position: absolute
					bottom: 0
					width: 100%
					height: 3px
					background: color(main)
					display: none
				span
					font-size: 20px
			+ li
				margin-left: r(60px)
			&.active
				> a
					&::after
						display: block
	@media screen and ( min-width: 1024.98px )
		> ul
			> li
				&:hover
					a
						color: color(main)
	@media screen and ( max-width: 1280.98px )
		> ul
			> li
				+ li
					margin-left: r(40px)
	@media screen and ( max-width: 1024.98px )
		> ul
			display: block
			> li
				+ li
					margin-left: 0
					margin-top: 24px
				> a
					color: #fff
					min-height: auto

.language
	.current
		display: flex
		align-items: center
		span
			margin-left: 5px
			font-size: 24px
	.dropdown-content
		padding-top: 15px
		line-height: 1.5
		min-width: auto
		width: 100%
		ul
			padding: 12px
			color: #333

.has-mega
	position: relative
	.mega-item
		position: absolute
		top: 100%
		left: -15px
		min-height: r(400px)
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05)
		display: none
		z-index: 2
		.zone-child-lv-1
			background: #333333
			min-width: r(210px)
			ul
				li
					a
						border-bottom: 1px solid rgba(255, 255, 255, 0.1)
						white-space: nowrap
						display: flex
						align-items: center
						min-height: 50px
						padding: 0 25px
						font-size: 14px
						color: #fff
						text-transform: uppercase
					&.active
						a
							background: color(main)
		.zone-child-lv-2-wrap
			display: none
		.zone-child-lv-2
			background: #fff
			width: 815px
			display: flex
			height: 100%
		.link-lv-2
			flex: 0 0 210px
			border-right: 1px solid #E1E1E1
			ul
				li
					a
						border-bottom: 1px solid #E1E1E1
						white-space: nowrap
						display: flex
						align-items: center
						min-height: 50px
						padding: 0 25px
						font-size: 14px
						text-transform: uppercase
					&.active,&:hover
						a
							color: color(main)
							text-decoration: underline
		.zone-child-lv-3-wrap
			display: none
			flex: auto
		.zone-child-lv-3
			display: flex
			justify-content: space-between
			padding: 10px 24px
			width: 100%
		.link-lv-3
			ul
				li
					a
						font-size: 14px
						line-height: 30px
					&.active,&:hover
						a
							color: color(main)
							text-decoration: underline
	@media screen and ( min-width: 1024.98px )
		&:hover
			.mega-item
				display: flex
		.toggle
			display: none
	@media screen and ( max-width: 1280.98px )
		.mega-item
			.zone-child-lv-2
				width: 710px
			.link-lv-3
				ul
					li
						a
							line-height: 1.75
	@media screen and ( max-width: 1024.98px )
		justify-content: space-between
		flex-wrap: wrap
		display: flex
		.mega-item
			position: static
			min-height: auto
			width: 100%
			padding-top: 15px
			.zone-child-lv-1
				min-width: auto
				background: #fff
				ul
					li
						border-bottom: 1px solid #cfcfcf
						a
							color: #333
				&.hover
					a
						background: color(main)
		> span
			font-size: 24px

.menu-toggle
	display: flex
	align-items: center
	justify-content: center
	width: 40px
	height: 40px
	color: #fff
	font-size: 24px
	background: color(main)

.search-toggle
	display: flex
	align-items: center
	justify-content: center
	width: 40px
	height: 40px
	color: #fff
	font-size: 20px
	background: #666

.offcanvas-overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: rgba(#000, 0.3)
	display: none
	z-index: 998

.menu-mobile-offcanvas
	color: #fff
	width: 270px
	left: -270px
	background: color(main)
	&.is-open
		transform: translate(270px,0,0)
	@media screen and ( min-width: 1024.98px )
		display: none
	.offcanvas-close
		display: none
	.offcanvas-wrap
		padding: r(40px) r(20px) r(40px)
		position: relative